import { parse } from 'path';
import { useApplicationStore } from '/stores/application';

export const fullUrl = (url) => {
  if (target !== undefined) {
    return 'https://***' + url
  }
}

export const formatUrl = (url) => {
  if (url !== undefined) {
    if (url.startsWith('https://')) {
      url = url.split('https://')[1]
    }
    if (url.startsWith('http://')) {
      url = url.split('http://')[1]
    }
    if (url.startsWith('www.')) {
      url = url.split('www.')[1]
    }
    if (url.includes('/')) {
      url = url.split('/')[0];
    }
    return url
  }
}

export const formatFileSize = (fileSizeInBytes) => {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  if (i === 0) {
    return Math.round(fileSizeInBytes) + byteUnits[i];
  } else {
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
};


export const toKebab = (string) => {
  if (typeof string !== "string") {
    return string
  } else {
    string = string.toLowerCase()
    string = string.replace(/[^a-zA-Z0-9 ]/g, '-') // remove specials
    string = string.replace(/ /g, '-') // remove spaces
    string = string.replace(/-{1,}/g, '-') // remove multiple dashes
    return string
  }
}


export const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
}


export const formatTel = (number) => {
  if (number === undefined) {
    return false
  }

  if (number.startsWith('0800')) {
    return number
  }
  // number = number.replace(/\s/g, '')
  if (number.startsWith('0')) {
    number = number.substring(1)
  }
  number = '+64 ' + number
  return number
}


export const is = (breakpoint) => {
  return store.state.breakpoint === breakpoint;
}


export const setMeta = (entry) => {
  const store = useApplicationStore();
  if (entry.seoTitle !== '' && entry.seoTitle !== null && entry.__typename !== 'listing_Entry')
    store.setTitle(entry.seoTitle)
  else if(entry.__typename === 'listing_Entry'){
    let stageDetail = getStageDetail(parseInt(entry.stage[0].number))
    let address = entry.streetAddress === null ? `Lot ${entry.lot}` : `Lot ${entry.lot} | ${entry.streetAddress}`

    if (entry.type === 'homes-for-sale'){
      
      if (entry.builder.length > 0){
        store.setTitle(`${address} | ${salesStatusTidy(entry.saleStatus)} | ${entry.builder[0].title} | Arbor Green`)
      }
      else{
        store.setTitle(`${address} | ${entry.status} | Arbor Green`)
      }
    }
    else{
      store.setTitle(`${address} | ${stageDetail.marketingLabel} | Arbor Green`)
    }
  }
  else{
    if (entry.sectionHandle === 'journalEntries')
      store.setTitle(entry.title + ' ' + store.titleSuffix)
    else
      store.setTitle(store.defaultTitle)
  }
    
  if (entry.seoDescription !== '' && entry.seoDescription !== null)
    store.setDescription(entry.seoDescription)
  if (entry.seoKeywords !== '' && entry.seoKeywords !== null)
    store.setKeywords(entry.seoKeywords)
}

export const salesStatusTidy = (status) => {
  switch (status){
    case 'for-sale':
      return 'For Sale';
    case 'sold':
      return 'Sold';
    case 'under-offer':
      return 'Under Offer';
    }
  return status;
}

export const setTheme = (theme) => {
  const store = useApplicationStore();
  const darkThemes = ['dark', 'rust', 'brown', 'warm-grey', 'sage-green', 'steel-blue', 'hd-blue'];
  const lightThemes = ['light','pale-blue','stone'];

  store.setTheme(theme)

  if (darkThemes.includes(theme)) { // dark themes
    store.setThemeType('dark')
    document.documentElement.style.setProperty('--bg', `var(--${theme})`);
    document.documentElement.style.setProperty('--text', 'var(--light)');
    document.documentElement.style.setProperty('--footer', `var(--${theme})`);
  } else if(lightThemes.includes(theme)) {
    store.setThemeType('light')
    document.documentElement.style.setProperty('--bg', `var(--${theme})`);
    document.documentElement.style.setProperty('--text', 'var(--dark)');
    document.documentElement.style.setProperty('--footer', `var(--dark)`);
  }
}


export const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};


export const formatPrice = (price) => {
  if (price >= 1000000) {
    return `$${(price / 1000000).toFixed(3)}M`;
  } else if (price >= 1000) {
    return `$${(price / 1000).toFixed(0)}K`;
  } else {
    return `$${price}`;
  }
};


export const formatDate = (dateString) => {
  //const date = new Date(timestamp * 1000);
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getLotDetail = (lotId) => {
  const store = useApplicationStore();
  let lotDetail = {}
  if (lotId.includes('-') && lotId.includes('S'))
    lotDetail = store.listings.find((item) => item.id === lotId);
  else if(lotId.includes('/')){
    lotDetail = store.listings.find((item) => item.lotLabel === lotId);
  } 
  else
    lotDetail = store.listings.find((item) => item.serverSlug === lotId);
  
   

  if (!lotDetail) {
    console.error(`Lot with ID ${lotId} not found in listings.`);
    return false;
  }

  lotDetail.stageDetail = getStageDetail(lotDetail.stage);
  lotDetail.builderDetail = getBuilderDetail(lotDetail.builder);

  return lotDetail;
};


export const getStageDetail = (stageId) => {
  const store = useApplicationStore();
  let cleanStageId;

  if (typeof stageId === 'string') {
    cleanStageId = parseInt(stageId.replace('S', ''), 10);
  } else if (typeof stageId === 'number') {
    cleanStageId = stageId;
  }

  const stageDetail = store.stages.find((item) => item.id === cleanStageId);
  if (!stageDetail) {
    console.warn(`Stage with ID ${stageId} not found.`);
    return false;
  }
  return stageDetail;
};

export const getBuilderDetail = (builderId) => {
  if(builderId === null) { return false; }
  
  const store = useApplicationStore();
  const builderDetail = store.builders.find((item) => item.id === builderId);

  if (!builderDetail) {
    console.warn(`Builder with ID ${builderId} not found.`);
    return null;
  }
  return builderDetail;
};

export const getServerOriginal = (src) => {
  const store = useApplicationStore();
  const serverImageTarget = src.replace('/resources/', '').replace(/\.[^/.]+$/, "")
  const manifesto = store.serverImageManifesto[serverImageTarget] !== undefined ? store.serverImageManifesto[serverImageTarget] : null;
  return manifesto !== null ? store.fullURL + store.serverImagePrefix + manifesto.original : src
};


export const getServerBackground = (src) => {
  const store = useApplicationStore();
  const serverImageTarget = src.replace('/resources/', '').replace(/\.[^/.]+$/, "")
  const manifesto = store.serverImageManifesto[serverImageTarget] !== undefined ? store.serverImageManifesto[serverImageTarget] : null;
  return manifesto !== null ? store.fullURL + store.serverImagePrefix + manifesto.background : src
};


export const smoothScrollToTop = (element, offset = 0, duration = 500) => {
  if (element) {
    const startPosition = window.pageYOffset;
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
    const distance = targetPosition - startPosition;
    let start = null;

    function step(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    }

    function easeInOutCubic(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t * t + b;
      t -= 2;
      return c / 2 * (t * t * t + 2) + b;
    }

    window.requestAnimationFrame(step);
  }
}

export const transposeListing = (listing,byPassSoldCheck=false,soldDetailView=false) => {
  let lotId = 'S'+listing.stage[0].number+'-L'+listing.lot
  let type = listing.type === 'sections-for-sale' ? 'section' : 'home'
  let listingRecord = {}
  let propertyFeatures = []
  let floorPlans = []
  let lotType = listing.lotType.length > 0 ? listing.lotType[0].title : ''
  if (typeof listing.propertyFeatures !== undefined){
    propertyFeatures = listing.propertyFeatures !== null ? listing.propertyFeatures : []
  }
  if (listing.saleStatus !== 'sold' || byPassSoldCheck === true){
    let image = {};
    if (listing.type === 'homes-for-sale'){
      image = listing.render.length > 0 ? listing.render[0] : null

      if (image) {
        image = { ...image, bg: getServerBackground(image.url) };
      }
      // merge any plan resources into plans array
      if (listing.floorPlan && listing.floorPlan.length > 0){
        let floorPlan = { ...listing.floorPlan[0], type: 'ground' }
        floorPlans.push(floorPlan)
      }
      if (listing.firstFloorPlan && listing.firstFloorPlan.length > 0){
        let firstFloorPlan = { ...listing.firstFloorPlan[0], type: 'first' }
        floorPlans.push(firstFloorPlan)
      } 
      if (listing.sitePlan && listing.sitePlan.length > 0){
        let sitePlan = { ...listing.sitePlan[0], type: 'site' }
        floorPlans.push(sitePlan)
      } 
    } 
    else{
      image = {
          url: '/lots/'+lotId+'.avif',
          // bg: '/lots/'+lotId+'.avif',
        }
    }
    
    // several entry points, double check before including in grid (i.e. restrict to recently Sold only)
    let gridInclude = soldDetailView ? false : true; 

    let builder = listing.builder.length > 0 ? listing.builder[0].id : null
    let homeSize = listing.homeSize !== null ? listing.homeSize : null
    listingRecord = {
      id: lotId,
      serverId: listing.id,
      serverSlug: listing.lot,
      status: listing.saleStatus,
      gridInclude: gridInclude,
      type: type,
      lotType: lotType,
      stage: parseInt(listing.stage[0].number),
      stageServerId: listing.stage[0].id,
      subStage: parseInt(listing.technicalStage[0].title),
      subStageServerId: listing.technicalStage[0].id,
      underLyingTitle: listing.underlyingTitle[0].title,
      underLyingTitleServerId: listing.underlyingTitle[0].id,
      soldOnCompletion: listing.soldOnCompletion,
      lot: listing.lot,
      lotLabel: listing.lot.replace(/-/g, '/'),
      lotSize: listing.lotSize,
      lotSort: parseInt(listing.lot),
      price: listing.price,
      priceHidden: listing.priceHidden,
      modified: listing.modified,
      image: image,
      blurb: listing.blurb,
      keyPoints: listing.keyPoints,
      homeSize: homeSize,
      builder: builder,
      features: propertyFeatures,
      amenities: {
        bed: listing.bed,
        bath: listing.bath,
        garage: listing.garage,
        living: listing.living
      },
      streetAddress: listing.streetAddress,
      secondaryImages: listing.secondaryImages,
      detail: {
        floorplanNorth: listing.floorplanNorth,
        floorplanImages: floorPlans
      },
      elevations: listing.elevations,
      salesFlyer: listing.salesFlyer,
      supplementaryDocuments: listing.supplementaryDocuments,
      serverSlug: listing.slug
    }
  }
  else{
    listingRecord = {
      id: lotId,
      serverId: listing.id,
      serverSlug: listing.lot,
      status: listing.saleStatus,
      gridInclude: false,
      type: type,
      lotType: lotType,
      lotLabel: listing.lot.replace(/-/g, '/'),
      lotSort: parseInt(listing.lot),
      lotSize: listing.lotSize,
      amenities: {
        bed: listing.bed,
        bath: listing.bath,
        garage: listing.garage,
        living: listing.living
      },
      image: image,
      stage: parseInt(listing.stage[0].number),
      stageServerId: listing.stage[0].id,
      subStage: parseInt(listing.technicalStage[0].title),
      subStageServerId: listing.technicalStage[0].id,
      underLyingTitle: listing.underlyingTitle[0].title,
      underLyingTitleServerId: listing.underlyingTitle[0].id,
      lot: listing.lot,
      salesFlyer: listing.salesFlyer,
      supplementaryDocuments: listing.supplementaryDocuments
    }
  }
  return listingRecord
}

export const extractLotSlug = (listingId) => {
  if (listingId.includes('-')){
    return listingId.split('-L')[1]
  }
  else{
    console.log('preview mode detected - pass through lotId as slug')
    return listingId
  }
}

export const transposeStage = (stage) => {
  let subStages = []
  stage.subStages.map(subStage => {
    subStages.push(parseInt(subStage.value))
  })
  let stageRecord = {}
  if (stage.default){
    stageRecord = {
      id: parseInt(stage.id),
      value: parseInt(stage.id),
      label: `Stage ${stage.id}`,
      marketingLabel: stage.label,
      default: stage.default,
      subStages: subStages,
      serverId:  parseInt(stage.serverId)
    }
  }
  else{
    stageRecord = {
      id: parseInt(stage.id),
      value: parseInt(stage.id),
      label: `Stage ${stage.id}`,
      marketingLabel: stage.label,
      subStages: subStages,
      serverId:  parseInt(stage.serverId)
    }
  }
  return stageRecord
}

export const transposeAgent = (agent) => {
  let image = agent.profilePhoto.length > 0 ? agent.profilePhoto[0] : null
  let agentRecord = {
    id: agent.id,
    name: agent.firstName + ' ' + agent.lastName,
    email: agent.email,
    phone: agent.phone,
    image: image,
    legal: agent.legal,
  }
  return agentRecord
}

export const transposeDocument = (document) => {
  let documentStage = document.stage.length ? parseInt(document.stage[0].number) : null
  let documentSubStage = document.subStage.length ? parseInt(document.subStage[0].title) : null
  let documentUnderlyingTitle = document.underlyingTitle.length ? document.underlyingTitle[0].title : null  
  let documentRecord = {
    id: document.id,
    title: document.title,
    stage: documentStage,
    subStage: documentSubStage,
    underLyingTitle: documentUnderlyingTitle,
    link: document.url,
    size: parseInt(document.size),
    type: document.format,
    mimeType: document.mimeType
  }
  return documentRecord
}

export const prepareFormFields = (formieForm,customFields) => {
  const formieHelperFieldTypeMappers = {
            'Field_Name': 'text',
            'Field_SingleLineText': 'text',
            'Field_Phone': 'phone',
            'Field_Email': 'email',
            'Field_Dropdown': 'select',
            'Field_MultiLineText': 'textarea',
            'Field_Number': 'number',
            'Field_Checkboxes': 'checkbox',
            'Field_FileUpload': 'file'};
  let formHelperFields = [];
  let fieldList = formieForm.formFields;
  fieldList.forEach((field, index) => {
    if (field.type !== 'Field_Hidden'){  // hidden fields need component level val setting and context (i.e. no dynamic hiddens)
      let helperType = formieHelperFieldTypeMappers[field.type];
      let placeHolder = field.placeholder;
      let value = ''
      if (placeHolder === null){
        // formie has no option for dropdown placeholder :-(
        placeHolder = field.name;
      }
      if (field.type === 'Field_Checkboxes'){
        // restructure for checkbox formie <-> frontend
        placeHolder = field.options[0].label;
        value = false
      }
      let newField  = {
          name: field.handle, 
          label: placeHolder,
          placeholder: placeHolder,
          type: helperType,
          value: value,
          required: field.required,
          options: field.options,
          maxLength: field.max,
        };
      formHelperFields.push(newField);
    }
  });

  
  if (customFields){
    customFields.forEach((field, index) => {
      formHelperFields.push(field);
    }
    )};
  
    // console.log(formHelperFields);
  return formHelperFields;
}

export const craftLinkToRoute = (craftLink) => {
  const handleRouteMappings = {
    'home': 'home',
    'about': 'about',
    'enquire': 'enquire',
    'propertiesForSale': 'properties-for-sale',
    'sections': 'sections-for-sale',
    'homes': 'homes-for-sale',
    'listing': 'house-section-detail',
    'neighbourhood': 'neighbourhood',
    'lifeInArborGreen': 'life-in-arbor-green',
    'lifeInRolleston': 'life-in-rolleston',
    'journal': 'journal',
    'journalEntries': 'journal-entry',
    'legal': 'legal',
    'documents': 'documents',
    'faqs': 'faqs'
  };
  const slugRoutes = ['listing','journalEntries'];
  //console.log(craftLink);
  //console.log(craftLink.typeHandle);
  let routeName = '';
  let convertedTypeName = ''
  let listingSlug = ''
  if (craftLink.typeHandle === 'default'){
    // embedded link queries seem to result in different structure - transpose section handle from __typename
    // refine typename from courses_default_Entry -> courses
    let typeName = craftLink.__typename;
    convertedTypeName = typeName.replace('_default_Entry','');
    routeName = handleRouteMappings[convertedTypeName];
    if (craftLink.typeHandle === 'listing'){
      //console.log('listing type a')
      if (craftLink.listingType === 'sections-for-sale'){
        routeName = 'section-detail'
      }
      else{
        routeName = 'home-detail'
      }
      //console.log(routeName)
      listingSlug = 'S'+craftLink.stage[0].number+'-L'+craftLink.lot    
    }
    // console.log(convertedTypeName);
  }
  else{
    convertedTypeName = craftLink.typeHandle;
    routeName = handleRouteMappings[convertedTypeName];
    if (craftLink.typeHandle === 'listing'){
      //console.log('listing type b')
      if (craftLink.listingType === 'sections-for-sale'){
        routeName = 'section-detail'
      }
      else{
        routeName = 'home-detail'
      }
      //console.log(routeName)
      listingSlug = 'S'+craftLink.stage[0].number+'-L'+craftLink.lot
      //console.log('generated slug: '+craftLink.slug)
    }
    // console.log(convertedTypeName);
  }
    
  if (!slugRoutes.includes(convertedTypeName)){
    return {
      name: routeName
    };
  }
  else{
    // console.log(' slug');
    if (listingSlug !== ''){
      return {
        name: routeName,
        params: {
          slug: listingSlug
        }
      };
    }
    else{
      return {
        name: routeName,
        params: {
          slug: craftLink.slug
        }
      };
    }
  }
}