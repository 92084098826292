import { defineStore } from 'pinia';
import { computed } from 'vue';

export const useApplicationStore = defineStore('app', {
  id: 'app',
  state: () => ({
    breakpoint: '',
    loaded: false,
    pageLoaded: false,
    settingsLoaded: false,
    listingsLoaded: false,
    agBuilderId: "1743",
    cmsListings: [],
    stages: [],
    technicalStages: [],
    underlyingTitles: [],
    title: '',
    titleSuffix: ' | Arbor Green',
    defaultTitle: '',
    keywords: '',
    defaultKeywords: '',
    description: '',
    defaultDescription: '',
    fullURL: import.meta.env.VITE_BASE_URL,
    serverImagePrefix: '/public/server-generated/',
    formieEndPoint: '/actions/formie/submissions/submit',
    serverImageManifesto: {},
    listingEnquiryForm: {},
    csrfTokenName: '',
    seoDetails: [],
    csrfToken: '',
    siteId: 1,
    csrfToken: '',
    csrfTokenName: '',
    breakpoint: '',
    globalEmail: 'info@arborgreen.co.nz',
    globalAddress: '123 Address St',
    globalInstagram: 'ig',
    globalFacebook: 'fb',
    globalLocation: 'https://maps.google.com',
    globalFeatures: [],
    globalDocuments: [],
    themeType: '',
    theme: '',
    alertBar: {},
    jumpMenuTargets: [],
    listings: [],
    builders: [],
    agents: [],
    docs: [],
    docsPreload: null,
    articlesShown: 0,
    totalArticles: 0,
    userPrefs: {
      display : null,
      order : 'price',
      direction : 'low',
      stage: 'all',
      key: null,
    },
    listingsShown: {
      allProperties: null,
      homes: null,
      sections: null,
    },
    mapScrollDisabled: true,
  }),
  getters: {
    menuHeightCalc: (state) => computed(() => {
      const rootStyles = getComputedStyle(document.documentElement);
      const smallMenuHeight = rootStyles.getPropertyValue('--small-menu-height').trim();
      const desktopHeight = rootStyles.getPropertyValue('--large-menu-height').trim();

      return state.breakpoint.includes('xs') || state.breakpoint.includes('sm')
        ? parseInt(smallMenuHeight, 10)
        : parseInt(desktopHeight, 10);
    }),
    menuHeight() {
      return this.menuHeightCalc.value;
    },
  },
  actions: {
    setBreakpoint(val){
      this.breakpoint = val
    },
    setLoaded() {
      this.loaded = true
    },
    setPageLoaded(val){
      this.pageLoaded = val
    },
    setSettingsLoaded(){
      this.settingsLoaded = true
    },
    setServerImageManifesto(val){
      this.serverImageManifesto = val
    },
    setAlertBar(val){
      this.alertBar = val
    },
    setListingsLoaded(){
      this.listingsLoaded = true
    },
    setListings(val){
      this.listings = val
    },
    setStages(val){
      this.stages = val
    },
    setTechnicalStages(val){
      this.technicalStages = val
    },
    setUnderlyingTitles(val){
      this.underlyingTitles = val
    },
    setGlobalSectionFeatures(val){
      this.globalSectionFeatures = val
    },
    setGlobalFeatures(val){
      this.globalFeatures = val
    },
    setTotalArticles(val){
      this.totalArticles = val
    },
    setAgents(val){
      this.agents = val
    },
    setBuilders(val){
      this.builders = val
    },
    setDocuments(val){
      this.documents = val
    },
    setTitle(val){
      this.title = val
    },
    setTitleSuffix(val){
      this.titleSuffix = val;
    },
    setDefaultTitle(val){
      this.defaultTitle = val;
    },
    setDescription(val){
      this.description = val;
    },
    setDefaultDescription(val){
      this.defaultDescription = val;
    },
    setKeywords(val){
      this.keywords = val;
    },
    setDefaultKeywords(val){
      this.defaultKeywords = val;
    },
    setSeoDetails(val){
      this.seoDetails = val;
    },
    setCSRFToken(val){
      this.csrfToken = val
    },
    setCSRFTokenName(val){
      this.csrfTokenName = val
    },
    setBreakpoint(val){
      this.breakpoint = val
    },
    setGlobalEmail(val){
      this.globalEmail = val;
    },
    setGlobalAddress(val){
      this.globalAddress = val;
    },
    setGlobalLocation(val){
      this.globalLocation = val;
    },
    setGlobalInstagram(val){
      this.globalInstagram = val;
    },
    setGlobalFacebook(val){
      this.globalFacebook = val;
    },
    setGlobalDocuments(val){
      this.globalDocuments = val;
    },
    setThemeType(val){
      this.themeType = val;
    },
    setTheme(val){
      this.theme = val;
    },
    setUserPrefs(payload) {
      this.userPrefs = {
        ...this.userPrefs,
        ...payload
      };
    },
    setListingsShown(payload) {
      this.listingsShown = {
        ...this.listingsShown,
        ...payload
      };
    },
    setArticlesShown(payload) {
      this.articlesShown = payload;
    },
    setJumpMenuTargets(payload) {
      this.jumpMenuTargets.push(payload);
    },
    clearJumpMenuTargets() {
      this.jumpMenuTargets = [];
    },
    setDocsPreload(payload) {
      this.docsPreload = payload;
    },
    setListingEnquiryForm(payload) {
      this.listingEnquiryForm = payload;
    },
    setMapScrollDisabled(val){
      this.mapScrollDisabled = val;
    }
  },
})