<template>
  <default>
    <router-view></router-view>
  </default>
</template>

<script>
import { useApplicationStore } from '/stores/application'
import { useRoute } from 'vue-router';
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useHead } from '@unhead/vue'
import Default from '/components/layouts/Default.vue';
import { useLazyQuery, useQuery } from '@vue/apollo-composable'
import { SETTINGS_QUERY } from '~/gql/settings.js';
import { LISTINGS_QUERY } from '~/gql/listings.js';
import { transposeListing, transposeStage, transposeAgent, transposeDocument } from '~/helpers.js';
import Manifesto from '~/imageManifesto.json';

export default {
  components: { Default },
  setup() {
    const store = useApplicationStore()
    const route = useRoute();
    const breakpoint = ref('');
    const title = ref('Arbor Green');

    // Computed 
    const computedTitle = computed(() => {
      if (store.settingsLoaded){
        if (store.title !== '')
          return store.title;
        else if (route.meta.title !== '')
          return document.title
        else 
          return document.title;
        }
    });

    const computedDescription = computed(() => {
      if (store.settingsLoaded){
        if (store.description !== '')
          return store.description;
        else
          return store.defaultDescription
      }
    });


    // Methods
    const setup = () => {
      cssVars();
      console.log('%cStrategy: https://www.lunar.net.nz', 'color: #000000; font-weight: bold;');
      console.log('%cCopy: https://www.thecopyroom.co.nz', 'color: #000000; font-weight: bold;');
      console.log('%cDesign: https://www.bathgate.design', 'color: #000000; font-weight: bold;');
      console.log('%cDevelopment: https://kichin.co', 'color: #000000; font-weight: bold;');
      
      console.log('–');
      breakpointCheck();
    };
    
    // Meta
      useHead({
        title: computedTitle,
        meta: [
          { name: 'description', content: computedDescription}      
        ]
        ,
      })

    const breakpointCheck = () => {
      let w = document.documentElement.clientWidth;
      let curBreakpoint = '';

      if (w <= 576) {
        curBreakpoint = 'xs';
      } else if (w > 576 && w <= 767) {
        curBreakpoint = 'sm';
      } else if (w >= 768 && w <= 991) {
        curBreakpoint = 'md';
      } else if (w >= 992 && w <= 1199){
        curBreakpoint = 'lg';
      } else if (w >= 1200 && w <= 1439) {
        curBreakpoint = 'xl';
      } else {
        curBreakpoint = 'xxl';
      }

      if (curBreakpoint !== breakpoint.value) {
        breakpoint.value = curBreakpoint
        store.setBreakpoint(curBreakpoint)
        console.log('Breakpoint : ' + curBreakpoint)
      }
    };

    const cssVars = () => {
      let vh,vw;
      let method = 'window' // client

      if (method === 'client') {
        vh = document.documentElement.clientHeight * 0.01;
        vw = document.documentElement.clientWidth * 0.01;
      } else {  // default to using window
        vh = window.innerHeight * 0.01;
        vw = window.innerWidth * 0.01;
      }

      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      // usage : calc(var(--vh, 1vh) * 100);
    };

    const resize = (e) => {
      breakpointCheck();
      cssVars();
    };

    // Settings Data
    const { onResult, onError, result, loading, error, startPolling } = useQuery(SETTINGS_QUERY)
  
    onError(queryResult => {
      console.error(error.graphQLErrors)
      console.error(error.networkError)
    })
    
    onResult(queryResult => {
      if (!queryResult.loading){        
        // Contact
        store.setGlobalEmail(queryResult.data.contact.email);
        store.setGlobalAddress(queryResult.data.contact.address);
        store.setGlobalLocation(queryResult.data.contact.location);
        store.setGlobalInstagram(queryResult.data.contact.instagram);
        store.setGlobalFacebook(queryResult.data.contact.facebook);
        store.setDefaultTitle(queryResult.data.seo.title);
        store.setDefaultDescription(queryResult.data.seo.description);
        store.setDefaultKeywords(queryResult.data.seo.keywords);
        store.setBuilders(queryResult.data.builders);
        let stageSet = []
        queryResult.data.stages.forEach(function (stage, index) {
          let stageRecord = transposeStage(stage)
          stageSet.push(stageRecord)
        })
        store.setStages(stageSet);
        store.setTechnicalStages(queryResult.data.technicalStages);
        store.setUnderlyingTitles(queryResult.data.underlyingTitles);
        store.setGlobalFeatures(queryResult.data.propertyFeatures);
        if (queryResult.data.docs.length > 0)
          store.setGlobalDocuments(queryResult.data.docs[0].globalDocuments);
        let agentSet = []
        queryResult.data.agents.forEach(function (agent, index) {
          let agentRecord = transposeAgent(agent)
          agentSet.push(agentRecord)
        })
        store.setAgents(agentSet)
        store.setAlertBar(queryResult.data.alertBar)
        store.setTotalArticles(queryResult.data.articles.length)
        store.setListingEnquiryForm(queryResult.data.listingEnquiryForm)
        store.setSeoDetails(queryResult.data.seoDetails)
        // Load serverImageManifesto to complete 'settings' load
        const endpoint = store.fullURL+'/serverImageManifesto.json'
        fetch(endpoint)
          .then(response => response.json())
          .then(data => {
            store.setServerImageManifesto(data)
          })
          .then(() => {
            store.setSettingsLoaded()
            //console.log('1 Settings : Loaded')
          })
          .catch(error => {
            console.log('Error loading server image manifesto...')
            console.log(error)
          })  
      }
    })

    // Listings Data
    const { load: loadListingQuery, onResult: onListingResult, stale: listingResultsStale, result: listingResult, loading: listingLoading, error: listingError } = useLazyQuery(LISTINGS_QUERY)
    onListingResult(queryResult => {
      if (!queryResult.loading){        
        let listingSet = []
        //var startTime = performance.now()
        queryResult.data.availableListings.forEach(function (listing, index) {
          let listingRecord = transposeListing(listing)
          listingSet.push(listingRecord)
        })
        queryResult.data.recentlySoldHomes.forEach(function (listing, index) {
          let listingRecord = transposeListing(listing,true)
          listingSet.push(listingRecord)
        })
        queryResult.data.soldListings.forEach(function (listing, index) {
          let listingRecord = transposeListing(listing,true,true)
          // recentlySold overlap check
          let found = listingSet.find(element => element.id === listingRecord.id)
          if (found === undefined)
            listingSet.push(listingRecord) 
        })
        var endTime = performance.now()
        //console.log(`Listing transpose took ${endTime - startTime} milliseconds`)
        store.setListings(listingSet)
        store.setListingsLoaded()
        //console.log('2 Listings : Loaded')
        // listings in store - do image preload
        preloadImages(2500);
      }
    })

    const preloadImages = (delay = 2500) => { // delay in milliseconds
      const imagesToPreload = [];

      // Collect listing render/lot image URLs
      store.listings.forEach((listing) => {
        if (listing.status !== 'sold' && listing.type === 'home' && listing.image !== null) {
          imagesToPreload.push(listing.image.bg);
        }
      });

      // Collect marketing image URLs
      for (const key in Manifesto) {
        if (Manifesto.hasOwnProperty(key)) {
          const image = Manifesto[key];
          imagesToPreload.push('/' + image.background);
        }
      }

      let index = 0;

      function preloadImagesInIdle(idleDeadline) {
        while (idleDeadline.timeRemaining() > 0 && index < imagesToPreload.length) {
          const img = new Image();
          img.src = imagesToPreload[index];
          index++;
        }

        if (index < imagesToPreload.length) {
          requestIdleCallback(preloadImagesInIdle);
        }
      }

      function startPreloading() {
        if ('requestIdleCallback' in window) {
          requestIdleCallback(preloadImagesInIdle);
        } else {
          setTimeout(() => preloadImagesInIdle({ timeRemaining: () => 1000 }), 50);
        }
      }

      // Start preloading after the specified delay
      setTimeout(startPreloading, delay);
    };


    watch(() => store.settingsLoaded, (newValue, oldValue) => {
      if (store.settingsLoaded)
        loadListingQuery()
    });

    // Lifecycle Hooks
    onMounted(() => {
      setup();
      
      window.addEventListener("resize", resize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resize);
    });

    // Return properties/methods for the template
    return {
      computedTitle,
      computedDescription,
      resize,
      breakpointCheck,
      cssVars,
      result, loading, error, onResult, onError,
      listingResult, listingLoading, listingError, onListingResult
    };
  }
};
</script>